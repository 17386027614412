.fixed-secondary-top {
   position: fixed;
   top: 56px;
   right: 0;
   left: 0;
   z-index: 1030;
 }

.fixed-top-navbar-content {
   margin-top: 66px;
}

.fixed-top-2navbar-content {
   margin-top: 106px;
   margin-bottom: 90px;
}

.fixed-top-3navbar-content {
   margin-top: 146px;
   margin-bottom: 112px;
}

.nav-bar-row {
   height: 40px;
}

.products-filter button.dropdown-toggle {
   width: 100%;
   text-align: right;
}

@media (min-width: 576px) {
   .fixed-top-2navbar-content,
   .fixed-top-3navbar-content {
      margin-bottom: 56px;
   }
}